import React from 'react';
import { useParams } from 'react-router-dom';
import projectImages from '../ProjectImages/ProjectImages.json';
import './ProjectDescription.css';

const ProjectDescription = () => {
  const { projectNumber } = useParams();
  const description = projectImages[projectNumber - 1]?.description || 'No description available';

  return (
    <div>
      <div className="project-description">
        <h1>{projectImages[projectNumber - 1]?.title}</h1>
        <p>{description}</p>
      </div>
      <div className='ppl-images'>
        <img src='/projects/ppl.svg' alt="people illustration" />
      </div>
      {projectImages[projectNumber - 1]?.isSiteOn && (
        <div className='project-button'>
          <button>Visit Site</button>
        </div>
      )}
    </div>
  );
};

export default ProjectDescription;

