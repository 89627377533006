import React from 'react';
import { useParams } from 'react-router-dom';
import projectImages from './ProjectImages.json';
import './ProjectImages.css';

function ProjectImages() {
  const { projectNumber } = useParams();
  const images = projectImages[projectNumber - 1]?.images || [];

  return (
    <div className="project-images">
      {images.map((image, index) => (
        <img src={image} alt={projectImages[projectNumber - 1].title} key={index} />
      ))}
    </div>
  )
}

export default ProjectImages;

