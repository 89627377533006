import React, { useEffect } from "react";
import "./Form.css";
import { useTranslation } from 'react-i18next';

const Form = () => {
    const { t, i18n } = useTranslation(); // Include i18n here

    useEffect(() => {
        const currentLanguage = i18n.language; // Get the current language
        document.documentElement.setAttribute('dir', currentLanguage === 'ar' ? 'rtl' : 'ltr'); // Set direction
    }, [i18n.language]); // Dependency array includes language change

    return (
        <div>
            <div className="form-header">
                <h1 className="form-title">{t('form.title')}</h1> {/* Translated title */}
            </div>
            <div className="form-container">
                <div className="form-info">
                    <div className="first-name">
                        <label className="form-label">{t('form.firstName')}</label> {/* Translated first name label */}
                        <input className="form-input" type="text" />
                    </div>
                    <div className="last-name">
                        <label className="form-label">{t('form.lastName')}</label> {/* Translated last name label */}
                        <input className="form-input" type="text" />
                    </div>
                    <div className="email">
                        <label className="form-label">{t('form.email')}</label> {/* Translated email label */}
                        <input className="form-input" type="email" />
                    </div>
                </div>

                <div className="form-message">
                    <textarea className="form-input" placeholder={t('form.messagePlaceholder')} /> {/* Translated placeholder */}
                    <button className="form-button">{t('form.submit')}</button> {/* Translated submit button */}
                </div>
            </div>
        </div>
    );
};

export default Form;
