import PropTypes from 'prop-types';
import  { useState } from 'react';
import './servicesCard.css';

function Card({ title, description, color = '#fff', videoLink }) {
  const [showVideo, setShowVideo] = useState(false);
  const [isActive, setIsActive] = useState(false); // حالة جديدة لتتبع حالة الضغط

  const toggleVideo = () => {
    setShowVideo(!showVideo);
    setIsActive(!isActive); // تغيير الحالة عند الضغط
  };

  return (
    <article className="services-card" style={{ backgroundColor: isActive ? color : '#fff' }}> {/* تغيير اللون بناءً على الحالة */}
      <div className="card-content">
        <h2>
          {title}
          <span className="card-icon" onClick={toggleVideo} style={{ cursor: 'pointer' }}>
            <img src="./arrow-icon.svg" alt="More" />
          </span>
        </h2>
        <p>{description}</p>

        {/* عرض الفيديو بشكل شرطي */}
        {showVideo && (
          <div className="video-container">
            <iframe
              width="660"
              height="315"
              src={videoLink} // استخدام رابط الفيديو
              title={title}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        )}
      </div>
    </article>
  );
}

Card.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  color: PropTypes.string,
  videoLink: PropTypes.string, // إضافة نوع الرابط
};

export default Card;
