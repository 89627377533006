import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import reviewsData from './reviews.json'; // Adjust the path as necessary
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './Review.css';

const ReviewsSlider = () => {
  const [reviews, setReviews] = useState([]);
  const [slidesToShow, setSlidesToShow] = useState(2);

  useEffect(() => {
    setReviews(reviewsData);

    // Function to check screen width and adjust slidesToShow
    const handleResize = () => {
      if (window.innerWidth < 600) {
        setSlidesToShow(1);
      } else {
        setSlidesToShow(2);
      }
    };

    // Call the function on initial render
    handleResize();

    // Add event listener to handle window resize
    window.addEventListener('resize', handleResize);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: slidesToShow,
    slidesToScroll: slidesToShow,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  return (
    <div className="reviews-slider">
      <h2>What Our Clients Say About Our Services</h2>
      <Slider {...settings}>
        {reviews.map((review, index) => (
          <div
            key={review.id}
            className={`review-card ${index === 1 ? 'white-background' : ''}`}
          >
            {/* Review content */}
            <p>{review.review}</p>
            <div className="review-image">
              <img className="re-img" src={review.image} alt={`${review.name}'s review`} />
              <h3>{review.name}</h3>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default ReviewsSlider;
