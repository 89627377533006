import React from 'react';
import './footer.css';
import { FaFacebookF, FaLinkedinIn, FaInstagram, FaYoutube, FaTiktok, FaPhoenixFramework } from 'react-icons/fa';
import { FaVoicemail } from 'react-icons/fa6';

const Footer = () => {
  const phoneNumber = '+970566004310';
  const whatsappUrl = `https://wa.me/${phoneNumber}`;
  const email = 'stokerstechology@gmail.com';
  const emailUrl = `mailto:${email}`;

  return (
    <footer className="footer-container">
      <div className="footer-wave">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1440 320"
          preserveAspectRatio="none"
        >
          <path
            fill="#ffffff"
            fillOpacity="1"
            d="M1,224C120,288,240,224,360,192C480,160,600,192,720,280C880,224,960,224,1080,192C1200,160,1320,128,1440,160L1440,0L0,0Z"
          />
          <path
            fill="#8DABA8"
            fillOpacity="1"
            d="M0,224C120,288,240,224,360,192C480,160,600,192,720,208C840,224,960,224,1080,192C1200,160,1320,128,1440,160L1440,320L0,320Z"
          />
        </svg>
      </div>

      <div className="footer-content">
        <div className="logo-section">
          <img src="./logo.svg" alt="Logo" className="logo" />
          <h2>Stokes Technology</h2>
        </div>
        <div className="contact-info">
          <p className="contact-item">
            <a href={whatsappUrl} target="_blank" rel="noopener noreferrer">
              <i className="fas fa-phone"></i> {phoneNumber}
            </a>
          </p>
          <p className="contact-item">
            <a href={emailUrl}>
              <i className="fas fa-envelope"></i> {email}
            </a>
          </p>
        </div>

        <div className="footer-links">
          <a href="/support">Support</a>
          <a href="/services">Services</a>
          <a href="/about">About Us</a>
          <a href="/contact">Contact Us</a>
        </div>

        <div className="social-icons">
          <p>Follow Us</p>
          <div className="icons">
            <a href="https://www.facebook.com/profile.php?id=61565139111690&mibextid=ZbWKwL" target="_blank" rel="noopener noreferrer">
              <FaFacebookF />
            </a>
            <a href="https://www.instagram.com/stokers_techology?igsh=MXZqaDVkNTVhZ3hpeQ==" target="_blank" rel="noopener noreferrer">
              <FaInstagram />
            </a>
            <a href="https://www.tiktok.com/@stokers.technolog?_t=8puMBB9AMjf&_r=1" target="_blank" rel="noopener noreferrer">
              <FaTiktok />
            </a>
            <a href="#"><FaLinkedinIn /></a>
          </div>
        </div>
      </div>

      <div className="footer-bottom">
        <p>© 2024 Stokes Technology. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
