import React from 'react';
import './Support_Hero.css';
import { useTranslation } from 'react-i18next';

const Hero = () => {
  const { t } = useTranslation();

  return (
    <div>
      <div className="support-hero">
        <h1 className="support-hero-header">{t('support.hero.header')}</h1> {/* Translated header */}
        {/* Uncomment if needed */}
        {/* <img className="support-hero-bubbles" src="/support/bubble1.svg" alt={t('support.bubble1.alt')} />
        <img className="support-hero-bubbles" src="/support/bubble2.svg" alt={t('support.bubble2.alt')} /> */}
        <img className="phone-img" src="/support/phone.svg" alt={t('support.phone.alt')} />
      </div>
    </div>
  );
};

export default Hero;
