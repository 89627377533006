import React from 'react';
import './GetStartButten.css'; // Optional: your styles

const GetStartedButton = () => {
  const handleClick = () => {
    // Open WhatsApp link in a new tab
    window.open('https://wa.me/+970566004310', '_blank');
  };

  return (
    <div className="cta-section">
      <h2>Let's Build Your Future</h2>
      <p>
        Contact us today to discuss how we can bring your project to life with our expert digital solutions.
      </p>
      <button className="get-started-button" onClick={handleClick}>
        Get Started
      </button>
    </div>
  );
};

export default GetStartedButton;
