import './Hero.css';
import { useTranslation } from 'react-i18next';

const Hero = () => {
  const { t } = useTranslation();

  return (
    <div>
      <div className="hero">
        <div className="hero-svg">
          <img src="/header-svg-group.svg" alt={t('hero.img')} />
        </div>
        <div className="hero-content">
          <h1 className="hero-header">{t('about.hero.headr2')}</h1> {/* Translated header */}
          <p className="hero-p-normal">{t('about.welcome.content')}</p> {/* Translated content */}
          <p className="hero-p-bold">“{t('about.hero.paragraph')}”</p> {/* Translated bold text */}
        </div>
      </div>

      {/* Uncomment if needed
      <div className="hero-line">
        <img src="/aboutUsEmpty.svg" alt="a wiggly line" />
      </div> 
      */}
    </div>
  );
};

export default Hero;
