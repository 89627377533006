import React, { useState, useEffect } from "react";
import './FAQ.css'; 
import { useTranslation } from 'react-i18next';

const FAQ = () => {
  const { t, i18n } = useTranslation(); // Destructure i18n here

  // Retrieve the FAQ data from the translation file
  const faqs = t('faq.items', { returnObjects: true });

  const [activeIndex, setActiveIndex] = useState(null);

  // Toggle function for each question
  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  // Effect to set document direction based on the selected language
  useEffect(() => {
    const currentLanguage = i18n.language;
    document.documentElement.setAttribute('dir', currentLanguage === 'ar' ? 'rtl' : 'ltr');
  }, [i18n.language]);

  return (
    <div className="faq-section">
      <h1 className="faq-title">{t('faq.title')}</h1>
      <div className="faq-list">
        {faqs.map((faq, index) => (
          <div key={index} className="faq-item">
            <div className="faq-question" onClick={() => toggleFAQ(index)}>
              {faq.question}
              <span className="faq-icon">{activeIndex === index ? "-" : "+"}</span>
            </div>
            {activeIndex === index && <div className="faq-answer">{faq.answer}</div>}
          </div>
        ))}
      </div>
    </div>
  );
};

export default FAQ;
