import {Project_Images,ProjectDescription} from "./index";

function Project() {
    return (
    <div>
        <Project_Images/>
        <ProjectDescription/>
    </div>

    )
}

export default Project;