import './FeaturedProjects.css'; // Import your custom CSS for styling

const projects = [
  {
    image: './proj.svg',
    title: 'What Are Product Goals and How You Can Set Them Up for Your Business?',
  },
  {
    image: './proj.svg',
    title: 'What Are Product Goals and How You Can Set Them Up for Your Business?',
  },
  {
    image: './proj.svg',
    title: 'What Are Product Goals and How You Can Set Them Up for Your Business?',
  },
  {
    image: './proj.svg',
    title: 'What Are Product Goals and How You Can Set Them Up for Your Business?',
  },
  {
    image: './proj.svg',
    title: 'What Are Product Goals and How You Can Set Them Up for Your Business?',
  },
  {
    image: './proj.svg',
    title: 'What Are Product Goals and How You Can Set Them Up for Your Business?',
  },
];

const FeaturedProjects = () => {
  return (
    <section className="featured-projects">
      <h2 className="section-title">Featured Projects</h2>
      <div className="projects-grid">
        {projects.map((project, index) => (
          <div className="project-card" key={index}>
            <img src={project.image} alt={project.title} className="project-image" />
            <div className="project-info">
              <h3 className="project-title">{project.title}</h3>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default FeaturedProjects;
