import React, { useState, useEffect } from 'react';
import './WhyChooseUs.css';
import whyChooseUsData from './WhyChooseUs.json';

const WhyChooseUs = () => {
  const [currentIndex, setCurrentIndex] = useState(1);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((currentIndex + 1) % Object.keys(whyChooseUsData).length + 1);
    }, 5000);
    return () => clearInterval(interval);
  }, [currentIndex]);

  const { header, description } = whyChooseUsData[currentIndex];

  return (
    <div className="why-choose-us-container">
      <div className="why-choose-us-frame">
        <h1 className="why-choose-us-text">{header}</h1>
        <p className="why-choose-us-description">{description}</p>
      </div>
      <div className="why-choose-us-border"></div>
    </div>
  );
};

export default WhyChooseUs;

