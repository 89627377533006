import React, { useEffect } from "react";
import './welcome.css'; // Assuming you have a CSS file for styles
import { useTranslation } from 'react-i18next';

const Welcome = () => {
    const { t, i18n } = useTranslation();

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    };

    // This useEffect will change the page direction when the language is Arabic
    useEffect(() => {
        const currentLanguage = i18n.language;
        if (currentLanguage === 'ar') {
            document.documentElement.setAttribute('dir', 'rtl');
        } else {
            document.documentElement.setAttribute('dir', 'ltr');
        }
    }, [i18n.language]);

    return (
        <div>
            <div className="welcome-section">
                <div className="welcome-content">
                    <p>{t('welcome.content')}</p>
                </div>
                <div className="welcome-image">
                    <img src="/logo.svg" alt="Strokers technology's logo" />
                    <img src="/welcome-svg.svg" alt={t('welcome.welcome-image')} />
                </div>
            </div>
            <div className="welcome-line">
                <img src="/welcome-svg-line.svg" alt={t('welcome.arrow')} />
            </div>
        </div>
    );
};

export default Welcome;
