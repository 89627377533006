import React from 'react';
import './header.css'; // Add CSS styles for customization
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom'; // Import Link from React Router

const VisionSection = () => {
    const { t, i18n } = useTranslation();
    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    };

    return (
        <section className="vision-section">
            <div className="vision-content">
                <h2>{t('hero.headr2')}</h2>
                <p>"{t('hero.paragraph')}"</p>
                {/* Use Link to navigate to /about */}
                <Link to="/about">
                    <button>{t('hero.contact')}</button>
                </Link>
            </div>
            <div className="vision-image">
                <img src="/header-svg-group.svg" alt={t('hero.img')} />
            </div>
        </section>
    );
};

export default VisionSection;
