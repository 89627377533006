import {Hero,Welcome,ServicesHome,Projects} from './index'

function Home() {
  return (
    <>
      <Hero />
      <Welcome />
      <main>
        <ServicesHome />
        <Projects />
      </main>
    </>
  )
}

export default Home