import "./Support_Contact.css";


function Support_Contact() {
    return (<div>
        <div className="support-contact">
            <div className="support-contact-card">
                <h1 className="support-contact-header">Contact Information</h1>
                <p>Feel free to reach out to us using any of the methods below:</p>
                <div className="support-contact-info">
                    <div className="contact-item">
                        <img src="support/phone_icon.svg" alt="phone icon" />
                        <p>+970 566 004 310</p>
                    </div>
                    <div className="contact-item">
                        <img src="support/email_icon.svg" alt="email icon" />
                        <p>stokerstechology@gmail.com</p>
                    </div>
                </div>
            </div>

            <div className="support-contact-soicals">
                <h1 className="support-contact-header"> FOLLOW OUR SOCIALS </h1>
                <img src="/support/icons/facebook.svg" alt="facebook icon" />
                <img src="/support/icons/instagram.svg" alt="instagram icon" />
                <img src="/support/icons/twitter.svg" alt="twitter icon" />
                <img src="/support/icons/linkedin.svg" alt="linkedin icon" />
                <img src="/support/icons/whatsapp.svg" alt="whatsapp icon" />
            </div>
        </div>

    </div>)
}

export default Support_Contact