import {AboutUs_Hero,WhyChooseUs,FAQ} from './index'

function About() {
  return (
    <div>
      <AboutUs_Hero/>
      <WhyChooseUs/>
      <FAQ/>
    </div>
  )
}

export default About