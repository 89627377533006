import {Support_Hero,Support_Form,Support_Contact} from './index'
function Support() {
  return (
    <div>
        <Support_Hero/>
        <Support_Form/>
        <Support_Contact/>
    </div>
  )
}

export default Support