import  { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, ReviewsSlider, GetStartedButton } from './index'; // Adjust path if necessary
import './page.css';

function Services() {
  const { t, i18n } = useTranslation();
  const [showAll, setShowAll] = useState(false);

  const allServices = () => {
    setShowAll(!showAll);
  };

  // Array of services
  const services = [
    {
      title: t('servicesPage.video.webDevelopment.title'),
      description: t('servicesPage.video.webDevelopment.description'),
      videoLink: "https://www.youtube.com/embed/96zuWZs9g3E?si=3cRRHs0MNe0IaPtz",
    },
    {
      title: t('servicesPage.video.APICreation.title'),
      description: t('servicesPage.video.APICreation.description'),
      videoLink: "https://www.youtube.com/embed/eUm8I3TBITk?si=HbHtoE0wkeVqmgFX",
    },
    {
      title: t('servicesPage.video.AppDevelopment.title'),
      description: t('servicesPage.video.AppDevelopment.description'),
      videoLink: "https://www.youtube.com/embed/UVlwu59987s?si=XZmfuZyJOlkRR212",
    },
    {
      title: t('servicesPage.video.DesktopApplication.title'),
      description: t('servicesPage.video.DesktopApplication.description'),
      videoLink: "https://www.youtube.com/embed/Fi-muDMgu9E?si=nfboUM_85lxQzREe",
    },
    {
      title: t('servicesPage.video.SearchEngineOptimization.title'),
      description: t('servicesPage.video.SearchEngineOptimization.description'),
      videoLink: "https://www.youtube.com/embed/rIVB5OUtTvI?si=CyJvlQIZ9oH4NFpY",
    },
    {
      title: t('servicesPage.video.MaintenanceAndSpeed.title'),
      description: t('servicesPage.video.MaintenanceAndSpeed.description'),
      videoLink: "https://www.youtube.com/embed/guBicamKHnM?si=CT3M4RYIY5XGFMtm",
    },
  ];

  useEffect(() => {
    const currentLanguage = i18n.language;
    if (currentLanguage === 'ar') {
      document.documentElement.setAttribute('dir', 'rtl');
    } else {
      document.documentElement.setAttribute('dir', 'ltr');
    }
  }, [i18n.language]);

  return (
    <section className="services-container">
      <header className="header">
        <h1>{t('servicesPage.header.title')}</h1>
        <p>{t('servicesPage.header.description')}</p>
      </header>

      <section className='s-img'>
        <img src="./services-img.svg" alt={t('servicesPage.header.imageAlt')} />
      </section>

      <section className="services">
        {services.map((service, index) => (
          <Card
            key={index} // Use a unique key in production
            color='var(--primary-color)'
            title={service.title}
            description={service.description}
            videoLink={service.videoLink} // Pass the video link from the array
          />
        ))}

        {showAll && (
          <>
            {services.slice(3).map((service, index) => (
              <Card
                key={index + 3} // Adjust key for the additional cards
                title={service.title}
                description={service.description}
                videoLink={service.videoLink}
                 color='var(--primary-color)'
              />
            ))}
          </>
        )}
      </section>
      <div className='s-but'>
        <a id='allServices' onClick={allServices}>
          {showAll ? t('See Less') : t('See All Services')}
        </a>
      </div>


      <section className='Review'>
        <ReviewsSlider />
      </section>
      <section>
        <GetStartedButton />
      </section>
    </section>
  );
}

export default Services;
