import  { useState } from 'react';
import './servicesHome.css'; // Import custom CSS for styling
import { useTranslation } from 'react-i18next';

const Services = () => {
  const { t } = useTranslation(); // Initialize translation function

  const services = [
    {
      titleKey: 'services.Web_Development', // Key for translation
      image: './WebDevelopment.svg',
      summaryKey: 'services.Web_Development_Summary', // Key for translation summary
    },
    {
      titleKey: 'services.App_Development',
      image: './AppDevelopment.svg',
      summaryKey: 'services.App_Development_Summary',
    },
    {
      titleKey: 'services.API_Creation',
      image: './APICreation.svg',
      summaryKey: 'services.API_Creation_Summary',
    },
    {
      titleKey: 'services.Desktop_Application',
      image: './DesktopApplicationDevelopment.svg',
      summaryKey: 'services.Desktop_Application_Summary',
    },
  ];

  const [flipped, setFlipped] = useState(Array(services.length).fill(false)); // Track the flipped state for each card

  const handleFlip = (index) => {
    setFlipped((prevState) => {
      const newFlipped = [...prevState];
      newFlipped[index] = !newFlipped[index]; // Toggle the flipped state of the clicked card
      return newFlipped;
    });
  };

  return (
    <section className="services-section">
      <h2 className="services-title">{t('services.title')}</h2>
      
      {/* Use translation for the heading and description */}
      <h3 className="what-we-offer">{t('services.headr')}</h3>
      <p className="description">{t('services.paragraph')}</p>

      {/* Map over the services array and translate the title */}
      <div className="services-grid">
        {services.map((service, index) => (
          <div
            className={`service-card ${flipped[index] ? 'flipped' : ''}`}
            key={index}
            onClick={() => handleFlip(index)}
          >
            {/* Front of the card */}
            <div className="front">
              <img src={service.image} alt={t(service.titleKey)} className="service-icon" />
              <h4>{t(service.titleKey)}</h4>
            </div>

            {/* Back of the card */}
            <div className="back">
              <p>{t(service.summaryKey)}</p>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Services;
