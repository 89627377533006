import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import './navbar.css'; // Custom CSS
import { Link } from 'react-router-dom';

const NavBar = () => {
  const { t, i18n } = useTranslation();
  const [isMenuOpen, setIsMenuOpen] = useState(false); // State for tracking menu open/close

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };
  

  // Toggle the mobile menu open/close
  const toggleMenu = () => {
    setIsMenuOpen((prevState) => !prevState); // Toggle state
  };

  return (
    <nav className="navbar">
      {/* Logo Section */}
      <div className="logo">
        <img src="./logo.svg" alt="Site Logo" />
        <samp>{t('navbar.welcome')}</samp>
      </div>

      {/* Navigation Links */}
      <ul className={`nav-links ${isMenuOpen ? 'active' : ''}`}>
  <li>
    <Link to="/" onClick={toggleMenu}>{t('navbar.home')}</Link>
  </li>
  <li>
    <Link to="/about" onClick={toggleMenu}>{t('navbar.about')}</Link>
  </li>
  <li>
    <Link to="/services" onClick={toggleMenu}>{t('navbar.services')}</Link>
  </li>
  <li>
    <Link to="/support" onClick={toggleMenu}>{t('navbar.support')}</Link>
  </li>
</ul>



      {/* Right-side items */}
      <div className="right-nav-items">
        {/* Translation Icon */}
        <div className="translation-icon" onClick={() => changeLanguage(i18n.language === 'en' ? 'ar' : 'en')}>
          <img
            src="./translation.png"
            alt="Translate"
            style={{ cursor: 'pointer' }}
          />
        </div>

        {/* WhatsApp Contact Button */}
        <a href="https://wa.me/+970566004310" className="whatsapp-btn" target="_blank" rel="noopener noreferrer">
          {t('navbar.contact')}
        </a>
      </div>

      {/* Menu Toggle Button (hamburger icon) */}
      <div className="menu-toggle" onClick={toggleMenu}>
        <img
          src={isMenuOpen ? './menu-icon-svg.png' : './menu-icon-svg.png'}
          alt={isMenuOpen ? 'Close Menu' : 'Open Menu'}
        />
      </div>
    </nav>
  );
};

export default NavBar;
