import './Main.css';
import { HashRouter as Router, Routes, Route } from 'react-router-dom'; // Use HashRouter
import { Navbar, Home, Services, About, Support, Footer, Project } from './components/router';

function App() {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/services" element={<Services />} />
        <Route path="/support" element={<Support />} />
        <Route path="/project/:projectNumber" element={<Project />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;